<template>
  <div class="modal is-active">
    <div class="modal-background pointer"></div>
    <div class="modal-card">
      <section class="modal-card-body p-2">
        <div class="container is-size-6 mb-3 mt-1">
          As your device has blocked automatic location detection, please mark your location below by moving the blue marker.
        </div>
        <div class="container">
          <div id="map" class="select-location-map"></div>
          <button
          @click="setUserLocation()" class="button has-text-weight-semibold is-primary-colors is-fullwidth mt-2"
          :disabled="!lat || !lon"
          >
          Set as my current location
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import L from "leaflet";

export default {
  name: 'SelectLocation',
  data() {
    return {
      lat: null,
      lon: null
    }
  },
  computed: {
    userLocation(){
      return this.$store.state.userLocation
    }
  },
  mounted(){
    this.initMap()
  },
  methods: {
    initMap() {
      let location = [0,0]
      let zoom = 2
      if (this.userLocation) {
        location = [
          this.userLocation.lat,
          this.userLocation.lon
        ]
        zoom = 15
      }
      const maxRadius = 50
      this.map = L.map('map').setView(location, zoom)
      this.tileLayer = L.tileLayer(
        'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=V2XqbAbuAC6W5SqSo3tQXBESfHSlqC5Q'
        // {
        //   attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
        // }
      )
      const marker = L.marker(location, {draggable: true}).addTo(this.map)
      this.tileLayer.addTo(this.map)
      marker.on('dragend', () => {
        this.lat = marker.getLatLng().lat
        this.lon = marker.getLatLng().lng
      })
    },
    setUserLocation(){
      // this.$store.commit('changeUserLocation', {
      //   lat: this.lat,
      //   lon: this.lon
      // })
      this.$store.commit('getUserLocationMutation', {
        userAllowsLocationDetection: true,
        lat: this.lat,
        lon: this.lon
      })
      this.$router.go(-1)
    }
  }
}
</script>

<style>
.select-location-map{
  width: 100%;
  height: 450px;
}
</style>
